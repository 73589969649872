import $ from "jquery";
import Table from "./table";
import Home from "./home";
import Menu from "./menu";
import Project from "./project";
import Projects from "./projects";
import Works from "./works";

global.jQuery = $;

if (module.hot) {
  module.hot.accept();
}

window.isMobile = false;
if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  window.isMobile = true;
}
if (navigator.maxTouchPoints == 5) {
  window.isMobile = true;
}

new Menu();

if ($(".home__logo").length > 0) {
  new Home();
}

if ($(".main--project").length > 0) {
  var project = new Project();
  project.init();
}

if ($(".main--magazine").length > 0) {
  var Magazine = require("./magazine");
  new Magazine();
}

if ($(".main--works").length > 0) {
  new Works();
}

if ($(".main--projects").length > 0) {
  var project = new Project();
  new Projects(project);
} else {
  if ($(".js-projectPopup").length > 0) {
    var project = new Project();
    new Projects(project);
  }
}

if ($(".js-tablePopup").length > 0) {
  let prev = false;
  if ($(".js-tablePopup").hasClass("js-tablePopup--prev")) {
    prev = true;
  }
  new Table(prev);
}

/* fancy text indent after br */
$(".main--default p br").after('<span class="br-spacer"></span>');
$(".table-content__child p br").after('<span class="br-spacer"></span>');

$(document).on("click", ".js-toggleMute", function (e) {
  $vid = $(e.currentTarget).parent().find("video");
  $vid.prop("muted", !$vid.prop("muted"));
  $(e.currentTarget).toggleClass("active");
});
