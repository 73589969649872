import $ from "jquery";

export default class Table {
  constructor(prev) {
    this.initEvents();

    this._hash = new URL(document.URL).hash;
    if (this._hash) {
      const hashEl = document.querySelector(this._hash);
      setTimeout(() => {
        this.toggleSub(hashEl);
      }, 300);
    } else if (prev) {
      this.openFirstRows();
    }
  }
  openFirstRows() {
    var $rows = $(".table-content__parent");
    var $firstRows = $rows.slice(0, 3);
    $firstRows.each(function () {
      $(this).next("tr").addClass("is-visible");
    });
    $firstRows.addClass("is-active");
  }
  initEvents() {
    $(".js-tablePopup").on("click", (e) => {
      e.preventDefault();
      this.toggleSub(e.currentTarget);
    });
    $(".js-mobileSort").change(this.handleMobileSort.bind(this));
  }

  handleMobileSort(e) {
    var $this = $(e.currentTarget);
    var val = $this.find("option:selected").attr("value");

    window.location = $this.data("url") + "/sort:" + val + "/dir:asc";
  }

  toggleSub(el) {
    var $el = $(el);
    var $child = $el.next("tr");

    if ($el.hasClass("js-directLink")) {
      window.location = $el.data("url");
      return;
    }

    if (!$child.hasClass("is-visible")) {
      if ($el.hasClass("table-content__parent--inactive")) {
        Velocity($el, "scroll", {
          duration: 500,
          offset: -90,
          easing: "easeOutQuint",
        });
        return;
      }

      Velocity($child, "scroll", {
        duration: 500,
        offset: -90,
        easing: "easeOutQuint",
      });

      $el.addClass("is-active");
      $child.addClass("is-visible");

      var $tds = $child.find(".td__inner");
      $tds.each(function () {
        var $this = $(this);

        $this.css({ height: "auto", "min-height": "0" });
        var h = $this.height();
        if (h < 100) h = 100;
        Velocity(
          $this,
          { height: [h + "px", 0] },
          600,
          "easeOutQuint",
          function () {
            $this.css({ height: "", "min-height": "100px" });
          }
        );
      });
    } else {
      $el.removeClass("is-active");

      var $tds = $child.find(".td__inner");
      $tds.each(function () {
        var $this = $(this);

        var h = $this.height();
        $this.css({ "min-height": 0 });
        Velocity(
          $this,
          { height: [0, h + "px"] },
          600,
          "easeOutQuint",
          function () {
            $child.removeClass("is-visible");
          }
        );
      });
    }
  }
}
