import $ from "jquery";

export default class Works {
  constructor() {
    this.initEvents();
  }
  initEvents() {
    $(".js-mobileSort").on("change", this.handleMobileSort.bind(this));
  }

  handleMobileSort(e) {
    var $this = $(e.currentTarget);
    var val = $this.find("option:selected").attr("value");

    window.location = $this.data("url") + "/sort:" + val + "/dir:asc";
  }
}
