import $ from "jquery";
import Velocity from "velocity-animate";

export default class Project {
  constructor(project) {
    this.project = project;
    this.initEvents();

    this.title = document.title;
    this.url = document.URL;
  }
  initEvents() {
    $(".js-projectPopup").on("click", this.openProject.bind(this));
    $(".js-toggleFilter").on("click", this.toggleFilter.bind(this));
  }

  toggleFilter(e) {
    if (e) e.preventDefault();

    var $target = $(".projectfilter");

    if ($target.is(":visible")) {
      Velocity($target, { opacity: [0, 1] }, 300, function () {
        $target.hide();
      });

      $(".main").removeClass("is-blurred");
    } else {
      $target.show();

      $(".main").addClass("is-blurred");

      var $inner = $target.find(".projectfilter__inner");
      Velocity($target, { opacity: [1, 0] }, 300);
      Velocity(
        $inner,
        { opacity: [1, 0], translateY: [0, "20px"] },
        400,
        "ease-out"
      );
    }
  }

  openProject(e) {
    if (e) e.preventDefault();
    var self = this;
    var $link = $(e.currentTarget);

    var url = $link.attr("href") || $link.data("url");

    $("body").css("overflow-y", "hidden");
    var $overlay = $(
      '<div class="project-overlay"><div class="loader"></div></div>'
    );
    $("body").append($overlay);
    Velocity($overlay, { opacity: [1, 0] }, 200);

    $.get(
      url,
      function (data) {
        var pageData = $(data).filter(".main--project");

        if (!pageData) {
          $overlay.remove();
          return;
        }

        $overlay.html(pageData);
        pageData
          .hide()
          .delay(200)
          .fadeIn(300, function () {
            self.project.init();
          });

        var title = $(data).filter("title").text();
        document.title = title;

        history.pushState({ url: url }, null, url);

        $overlay.find(".project__closelink").click(function (e) {
          if (e) e.preventDefault();
          document.title = self.title;
          history.pushState({ url: self.url }, null, self.url);

          Velocity(
            $overlay.find(".main--project"),
            { opacity: [0, 1] },
            200,
            function () {
              Velocity($overlay, { opacity: [0, 1] }, 200, function () {
                $overlay.remove();
                $("body").css("overflow-y", "");
              });
            }
          );
        });
      },
      "html"
    ).fail(function () {
      window.location = url;
    });
  }
}
