var $ = require("jquery");

var Magazine = function () {
  this.populateImages();
};

Magazine.prototype = {
  populateImages: function () {
    $(".magazine__split__right").each(function () {
      var $this = $(this);
      var $images = $this.find("figure");

      $this.parent().find(".js-magazineImageContainer").append($images.clone());
    });

    $(".magazine__split__right br").after('<span class="br-spacer"></span>');
  },
};

module.exports = Magazine;
