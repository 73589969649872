import $ from "jquery";

export default class Project {
  constructor() {
    this.initEvents();
  }

  init() {
    $(".project__info__inner p br").after('<span class="br-spacer"></span>');
  }

  initEvents() {
    var $d = $(document);
    $d.on("click", ".js-showProjectInfo", this.showInfo.bind(this));
    $d.on("click", ".js-hideProjectInfo", this.hideInfo.bind(this));
  }

  showInfo(e) {
    if (e) e.preventDefault();

    $(".project__info").fadeIn(200);
  }

  hideInfo(e) {
    if (e) e.preventDefault();

    $(".project__info").fadeOut(200);
  }
}
