import $ from "jquery";

export default class Menu {
  constructor() {
    this.initEvents();
  }

  initEvents() {
    $(".js-toggleMobileNav").on("click", function () {
      $(".header").toggleClass("is-visible");
      $(this).toggleClass("is-active");
      $(".main").removeClass("is-blurred");
    });
  }
}
